import { UploadFile } from 'antd';
import { IAttribute, IEntity } from '../../common';

export type TEventPath =
  | ['/events']
  | ['/events/:eventId']
  | ['/events/:eventId/checkin'];

export interface IGooglePlace {
  label: string;
  value: {
    place_id: string;
    reference: string;
    description: string;
    matched_substrings: {
      offset: number;
      length: number;
    }[];
    structured_formatting: {
      main_text: string;
      secondary_text: string;
      main_text_matched_substrings: {
        offset: number;
        length: number;
      }[];
    };
    types: string[];
    terms: {
      offset: number;
      value: string;
    }[];
  };
}

export enum OccasionStatusEnum {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export enum OccasionCategoryEnum {
  ENTERTAINMENT = 'ENTERTAINMENT',
  WORKSHOP = 'WORKSHOP',
  EDUCATION = 'EDUCATION',
  FINANCE = 'FINANCE',
  KIDS_ACTIVATION = 'KIDS_ACTIVATION',
  CORPORATE = 'CORPORATE',
  HEALTHCARE = 'HEALTHCARE',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
}

export interface IOccasionFormValues {
  status: OccasionStatusEnum;
  name: string;
  start_date: Date;
  start_time: Date;
  end_date: Date;
  end_time: Date;
  description: string;
  capacity: number;
  categories: OccasionCategoryEnum[];
  latitude: number;
  longitude: number;
  cover: UploadFile[];
  address: string;
  for_family: boolean;
  qr_code_title: string;
  qr_code_footer: string;
}

export interface IOccasionAttributes extends IAttribute {
  id: string;
  status: OccasionStatusEnum;
  name: string;
  start_date: Date;
  end_date: Date;
  description: string;
  capacity: number;
  categories: OccasionCategoryEnum[];
  latitude: number;
  longitude: number;
  created_at: Date;
  updated_at: Date;
  cover: string;
  address: string;
  for_family: boolean;
  qr_code_title: string;
  qr_code_footer: string;
}

export interface IOccasion extends IEntity<'Occasion'> {
  attributes: IOccasionAttributes;
}

export interface IOccasionStatistics {
  tickets_sold: number;
  checked_in: number;
  target_tickets: number;
  remaining: number;
}
