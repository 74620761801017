import { EditOutlined, PlusOutlined, TruckOutlined } from '@ant-design/icons';
import { App, Button, Flex, Space, TableProps } from 'antd';
import clipboard from 'clipboardy';
import { useCallback, useState } from 'react';
import { FaPeopleRobbery, FaRegCopy } from 'react-icons/fa6';
import { TbMapPinCheck } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { TActionList } from '../../../common';
import { FormModal } from '../../../components/form_modal';
import { ActionListButton, DataTable } from '../../../core';
import { Dayjs } from '../../../utils/date';
import { OccasionForm } from '../components/occasion_form';
import { OccasionStatusChip } from '../components/status_chip';
import { useGetOccasionsQuery } from '../store/occasion.api';
import { IOccasionAttributes } from '../types';

export const EventList: React.FC = () => {
  const modalState = useState<boolean>(false);
  const [occasion, setOccasion] = useState<IOccasionAttributes>();
  const { data: occasions } = useGetOccasionsQuery();
  const navigator = useNavigate();
  const { message } = App.useApp();

  const actions: TActionList<IOccasionAttributes>['items'] = [
    {
      label: 'Edit',
      key: 'edit',
      icon: <EditOutlined />,
      action: (record) => {
        setOccasion(record);
        modalState[1](true);
      },
    },
    {
      label: 'Manage',
      key: 'manage',
      icon: <TruckOutlined />,
      action: (record) => {
        navigator(`/events/${record.key}`);
      },
    },
    {
      label: 'Check-In',
      key: 'checkin',
      icon: <TbMapPinCheck />,
      action: (record) => {
        navigator(`/events/${record.key}/checkin`);
      },
    },
    {
      label: 'Copy Link',
      key: 'copy_link',
      icon: <FaRegCopy />,
      action: (record) => {
        clipboard.write(`https://superapp.rahetbally.com/events/${record.key}`);
        message.success('Link copied to clipboard');
      },
    },
  ];

  const columns: TableProps['columns'] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <OccasionStatusChip status={status} />,
    },
    {
      key: 'start_date',
      title: 'Start Date',
      dataIndex: 'start_date',
      render: (date) => Dayjs.fromUTC(date).format('MMM D, YYYY'),
    },
    {
      key: 'start_time',
      title: 'Start Time',
      dataIndex: 'start_date',
      render: (date) => {
        return Dayjs.fromUTC(date).format('h:mm a');
      },
    },
    {
      key: 'end_date',
      title: 'End Date',
      dataIndex: 'end_date',
      render: (date) => Dayjs.fromUTC(date).format('MMM D, YYYY'),
    },
    {
      key: 'end_time',
      title: 'End Time',
      dataIndex: 'end_date',
      render: (date) => Dayjs.fromUTC(date).format('h:mm a'),
    },
    {
      key: 'capacity',
      title: 'Capacity',
      dataIndex: 'capacity',
      render: (capacity) => (
        <Space>
          <FaPeopleRobbery />
          {capacity}
        </Space>
      ),
    },
    {
      render: (_, record) => (
        <ActionListButton items={actions} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  const resetResource = useCallback(() => {
    setOccasion(undefined);
  }, []);

  return (
    <>
      <FormModal
        title={occasion ? 'Edit Event' : 'Create New Event'}
        state={modalState}
        resetResource={resetResource}
      >
        <OccasionForm resource={occasion} />
      </FormModal>
      <Space direction="vertical">
        <Flex justify="end" gap={16}>
          <Button type="primary" onClick={() => modalState[1](true)}>
            <PlusOutlined /> Create Event
          </Button>
        </Flex>
        <DataTable columns={columns} data={occasions || []} />
      </Space>
    </>
  );
};
