export enum PaymentStatusEnum {
  INITIATED = 'initiated',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum RedeemStatusEnum {
  CREATED = 'created',
  INITIATED = 'initiated',
  REDEEMED = 'redeemed',
  FAILED = 'failed',
}

export enum RedeemPaymentMethodEnum {
  CASH = 'cash',
  CREDIT = 'credit',
  POINTS = 'points',
  ORANGE_CASH = 'orange_cash',
}
