import { Tag } from "antd";
import { PaymentStatusEnum } from "../enums";

type TProps = {
    status: string;
}

export const PaymentStatus: React.FC<TProps> = ({status}) => {

    let color = 'greenblue';

    switch (status) {
        case PaymentStatusEnum.SUCCESS:
            color = 'green';
            break;
        case PaymentStatusEnum.INITIATED:
            color = 'orange';
            break;
        case PaymentStatusEnum.FAILED:
            color = 'red';
            break;
    }

    return <Tag color={color}>{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>
}